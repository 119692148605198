import * as React from 'react'

import Page from '../components/Page'
import Container from '../components/Container'
import { Category } from '../components/Category'
import PageHeader from '../components/PageHeader'
import FooterBanner from '../components/DynamicBanner/FooterBanner'
import analytics from '../analytics'
import { logEvent } from 'firebase/analytics'

const PodcastPage = (props: any) => {
  React.useEffect(() => {
    if (analytics) {
      logEvent(analytics, 'screen_visited', {
        screen: 'podcastit'
      })
    }
  }, [])
  return (
    <Page>
      <Container>
        <PageHeader title="Podcastit" />
        <Category title="" screenTitle="Uusimmat podcast-jaksot" description="" type={'list-podcast-files'} />
        <Category title="" screenTitle="Podcastit" description="" type={'list-podcast-series'} categoryIndex={1} />
        <FooterBanner />
      </Container>
    </Page>
  )
}

export default PodcastPage
